<script setup>
import {ref} from "vue";

const cards = ref([
  {
    coming_soon: false,
    title: "Rapidy Web Framework",
    description: "Minimalistic web framework for those who prioritize speed and convenience!",
    buttons: [
      {
        prependIcon: "mdi-github",
        color: "#5f77c8",
        text: "Github Repository",
        variant: "outlined",
        href: "https://github.com/rAPIdy-org/rAPIdy",
      }
    ],
  },
  {
    coming_soon: true,
    title: "JetBrains Plugin. WorkLife",
    description: "Work-Life Balance Tracker to stay in a good psychology state, track your activity, take breaks, and monitor your activities.",
    buttons: [
      {
        prependIcon: "mdi-github",
        color: "#5f77c8",
        text: "GitHub",
        variant: "outlined",
        href: "https://github.com/NewAnimalProjects/WorkLifeBalancePlugin",
      },
      {
        prependIcon: "mdi-web",
        color: "#5f77c8",
        text: "Web Site",
        variant: "outlined",
        href: "https://worklife.animalprojects.dev/",
      }
    ],
  },
  {
    coming_soon: true,
    title: "Tech Blog",
    description: "Coming soon",
    buttons: [
      {
        prependIcon: "mdi-web",
        color: "#5f77c8",
        text: "Web Site",
        variant: "outlined",
        href: "https://blog.animalprojects.dev/",
      }
    ],
  },
  {
    coming_soon: true,
    title: "Travel Guide",
    description: (
        "You're traveling but don't know how to find attractions, good restaurants, bars?\n" +
        "Travel Guide will help you!"
    ),
    buttons: [
      {
        prependIcon: "mdi-web",
        color: "#5f77c8",
        text: "Web Site",
        variant: "outlined",
        href: "https://travel.animalprojects.dev/",
      }
    ],
  },
  {
    coming_soon: true,
    title: "Personal Brand",
    description: (
        "Your name is brand, your doings are your business!\n" +
        "Build your brand faster and better with our platform!"
    ),
    buttons: [
      {
        prependIcon: "mdi-web",
        color: "#5f77c8",
        text: "Web Site",
        variant: "outlined",
        href: "https://brand.animalprojects.dev/",
      }
    ],
  },
  {
    coming_soon: true,
    title: "The Budget",
    description: (
        "Simple and user-friendly app to control and manage your budget carefully and clearly!"
    ),
    buttons: [
      {
        prependIcon: "mdi-web",
        color: "#5f77c8",
        text: "Web Site",
        variant: "outlined",
        href: "https://budget.animalprojects.dev/",
      }
    ],
  },
  {
    coming_soon: true,
    title: "Planner",
    description: "Coming soon",
    buttons: [
      {
        prependIcon: "mdi-web",
        color: "#5f77c8",
        text: "Web Site",
        variant: "outlined",
        href: "https://planner.animalprojects.dev/",
      }
    ],
  },
  {
    coming_soon: true,
    title: "Game",
    description: "Coming soon",
    buttons: [
      {
        prependIcon: "mdi-web",
        color: "#5f77c8",
        text: "Web Site",
        variant: "outlined",
        href: "https://game.animalprojects.dev/",
      }
    ],
  },
  {
    coming_soon: true,
    title: "Dev Game",
    description: "Coming soon",
    buttons: [
      {
        prependIcon: "mdi-github",
        color: "#5f77c8",
        text: "Web Site",
        variant: "outlined",
        href: "https://dev_game.animalprojects.dev/",
      }
    ],
  },
]);
</script>

<template>
  <v-item-group>
    <v-container>
      <v-row justify="start">
        <v-col>
          <h2>Actual projects</h2>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col
            v-for="card in cards.filter((card) => !card.coming_soon)"
            :key="card.title"
            cols="12"
            md="4"
        >
          <v-card
              :title=card.title
              :text=card.description
          >
            <template v-slot:actions>
                <v-btn
                    v-for="button in card.buttons"
                    :key="button.href"
                    :prepend-icon=button.prependIcon
                    :color=button.color
                    :text=button.text
                    :variant=button.variant
                    :href=button.href
                    target="_blank"
                    block
                ></v-btn>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<style scoped>

</style>
